import { render, staticRenderFns } from "./SkeletonUserProfileComponentGlobal.vue?vue&type=template&id=234d0702&scoped=true&"
import script from "./SkeletonUserProfileComponentGlobal.vue?vue&type=script&lang=js&"
export * from "./SkeletonUserProfileComponentGlobal.vue?vue&type=script&lang=js&"
import style0 from "./SkeletonUserProfileComponentGlobal.vue?vue&type=style&index=0&id=234d0702&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234d0702",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VIcon,VRow,VSkeletonLoader})
